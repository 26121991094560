.SubmissionStudent .col-submission {
    flex: 0 0 auto;
    width: 200px;
    padding: 10px;
    margin: 10px;
    border: 2px solid rgb(var(--color-6));
    border-radius: 10px;
    text-align: center;
}

.SubmissionStudent .col-submission:hover {
    background-color: rgba(var(--color-22),0.5);
    cursor: pointer;
}

@media screen and (max-width: 544px) {

    .SubmissionStudent .row-center {
        display: flex;
        justify-content: center;
    }

}