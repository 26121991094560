.CreateStudent {
    padding-bottom: 20px;
}

@media screen and (max-width:1500px) {
    .CreateStudent {
        padding-bottom: 0px;
    }
}

.CreateStudent .contenedor {
    margin-top: 2%;
    margin-bottom: 2% !important;
    background-color: rgba(var(--color-20), 0.8);
    border-radius: 15px;
    padding: 2%;
    max-width: 1500px;
    min-height: 510px;
    margin-left: auto;
    margin-right: auto;
    color: rgb(var(--color-7));
}

.CreateStudent .contenedor h3{
    color: rgb(var(--color-1));
}

.CreateStudent .titulo {
    padding-left: 5%;
    color: rgb(var(--color-22));
}

.CreateStudent input {
    background-color: rgb(var(--color-7));
}