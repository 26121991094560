
.Login .login-container{
    padding-top: 15vh;
}

.Login .login-titulo{
    text-align: center;
    margin-bottom: 0px;
    margin-top: 25px;
    color: rgb(var(--color-19));
}

.Login .img-logo{
    max-width: 400px;
    width: 90vw;
}

.Login .login-redirect{
    width:  auto;
    text-align: end;
    
}

.Login .login-redirect p{
        color: black !important;
        margin: 0px !important;
}

.Login .login-redirect a{
    color: black !important;
    font-size: small;
}

.Login p{
    margin-top: 10px !important;
    margin-left: 10px;
    margin-bottom: 0px;
    font-size: 15px;
    color: rgb(var(--color-19));
}

.Login .login-formulario{
    justify-content: center;
    height: 60px;
    opacity: 0.5;
    color: black !important; 
    border-radius: 15px;
    padding-right: 40px;
    border: 3px solid rgb(var(--color-25));
}

.Login .login-formulario :focus{
    border: 3px solid rgb(var(--color-25));
}

.Login .grupo-input{
    position: relative;
    z-index: 90;
}

.Login .icon-formulario{
    position: absolute;
    right: 13px;
    top: 21px;
    height: 20px;
    z-index: 100;
    color: transparent;
    
}

.Login .icon-eye-formulario{
    position: absolute;
    right: 13px;
    top: 21px;
    height: 20px;
    z-index: 100;
    color: rgb(var(--color-22));
}

.Login .icon-eye-formulario:hover{
    cursor: pointer;
}

.Login .accompanied{
    right: 39px !important;
}

.Login .input-check{
    border: 3px solid rgb(var(--color-1));
}

.Login .input-xmark{
    
    border: 3px solid rgb(var(--color-24));
}

.Login .check{
    color: rgb(var(--color-1)) !important;
}

.Login .xmark{
    color: rgb(var(--color-24)) !important;
}

.Login .message-error p{
    color: rgb(var(--color-24)) !important;
    text-align: start;
    margin: 0;
    margin-left: 10px;
    margin-top: 0px !important;
}
.Login .mt-10{
    margin-top: 10px !important;
}

.Login .delete-error {
    color: rgb(var(--color-24)) !important;
    background-color: #4f505200;
    border: 0;
}

.Login .login-button{
    margin-top: 10px;
    background-color: rgb(var(--color-25));
    border-color: rgb(var(--color-25));
}
.Login .login-button:active{
    border-color: rgb(var(--color-26));
    box-shadow: none;
}