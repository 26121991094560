.SubmissionTeacher .col-submission {
    flex: 0 0 auto;
    width: 200px;
    padding: 10px;
    margin: 10px;
    border: 2px solid rgb(var(--color-6));
    border-radius: 10px;
    text-align: center;
}

.SubmissionTeacher .col-submission:hover {
    background-color: rgba(var(--color-22),0.5);
    cursor: pointer;
}

.SubmissionTeacher .edit{
    position: absolute;
    top: 5px;
    right: 10px;
}

.SubmissionTeacher .edit svg:hover{
    color: rgb(var(--color-22));
    cursor: pointer;
}

.SubmissionTeacher .changeName{
    color: rgb(var(--color-1));
    background-color: rgba(var(--color-22),0);
    border-color: rgb(var(--color-1));
    border-style: solid;
    width: 300px;
    margin-left: 10px;
    display:inline-block;
}

@media screen and (max-width: 544px) {

    .SubmissionTeacher .row-center {
        display: flex;
        justify-content: center;
    }

}