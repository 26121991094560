.CourseTeacher .contenedor {
    margin-top: 20px;
    background-color: rgba(var(--color-20), 0.8);
    border-radius: 15px;
    padding: 20px;
    padding-left: 2%;
    max-width: 1500px;
    max-height: calc(100vh - (20vh + 30px + 48px + 20px));
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;
    color: rgb(var(--color-7));
}

.CourseTeacher .titulo {
    padding-left: 5%;
    color: rgb(var(--color-1));
}



.CourseTeacher .col-img {
    padding-top: 5px;
    padding-bottom: 5px;
    flex: 0 0 auto;
    width: 70px !important;
}

.CourseTeacher .col-name {
    flex: 0 0 auto;
    max-width: 300px !important;
    height: 60px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    padding-top: 14px;
    font-size: 20px;
}

.CourseTeacher .col-file {
    flex: 0 0 auto;
    width: 400px !important;
    margin: 5px;
    border-radius: 3px;
    background-color: rgba(var(--color-22), .4);
}

.CourseTeacher .card-file {
    position: relative;
}

.CourseTeacher .col-view {
    font-size: 25px;
    position: absolute;
    width: 25px;
    padding: 0px;
    top: 5px;
    right: 5px;
    color: rgba(var(--color-7), 1);
}

.CourseTeacher .col-view:hover {
    color: rgb(var(--color-1));
    cursor: pointer;
}