.MyProjectsAssistant{
    justify-content: center;
    padding-left: 170px;
    max-height: calc(100vh - calc(15vh + 24px));
    overflow-y: auto;
}

.MyProjectsAssistant .card-margin{
    padding-left: 7%;
    padding-right: 7%;
}

@media screen and (max-width: 1200px){
    .MyProjectsAssistant {
        padding-left: 100px;
    }
}

@media screen and (max-width: 1085px){
    .MyProjectsAssistant {
        padding-left: 50px;
    }
}

@media screen and (max-width: 842px){
    .MyProjectsAssistant {
        padding-left: 0px;
    }
}

@media screen and (max-width: 760px){

    .MyProjectsAssistant .card-margin{
        padding-top: 0px;
    }
}


@media screen and (max-height: 610px){

    .MyProjectsAssistant{
        max-height: calc(100vh - calc(93px + 24px));
    }
}
