.BriefStudent .name-project {
    background-color: rgb(var(--color-1));
    margin-bottom: 20px;
    border-radius: 10px;
    min-height: 50px;
}

.BriefStudent .note{
    background-color: rgba(var(--color-22),0.5);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;
}

.BriefStudent .name-project h1 {
    color: rgb(var(--color-20));
    font-size: 1.8rem;
    margin-bottom: 0px;
    padding-left: 20px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.BriefStudent .col-info {
    max-height: calc((70vh - (40px + 60px + 20px)));
    overflow-y: auto;
}

.BriefStudent .container-info .sticky {
    position: sticky;
    top: 0px;
    background-color: rgba(var(--color-11), 1);
    border-radius: 10px;
    padding-left: 10px;
    z-index: 3;
}
.BriefStudent .calendar .container-info{
    margin-bottom: 0px;
}

.BriefStudent .project-info {
    flex: 0 0 auto;
    width: 70%;
    padding-right: 10px;
}

.BriefStudent .calendar {
    padding-left: 10px;
    /* overflow-y: auto;
    max-height: calc((70vh - (50px))); */
}

.BriefStudent .col-img {
    padding-top: 5px;
    padding-bottom: 5px;
    flex: 0 0 auto;
    width: 70px !important;
}

.BriefStudent .col-name {
    flex: 0 0 auto;
    max-width: 300px !important;
    height: 60px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    padding-top: 14px;
    font-size: 20px;
}

.BriefStudent .col-file {
    flex: 0 0 auto;
    width: 400px !important;
    margin: 5px;
    border-radius: 3px;
    background-color: rgba(var(--color-22), .4);
}

.BriefStudent .card-file {
    position: relative;
}

.BriefStudent .col-view {
    font-size: 25px;
    position: absolute;
    width: 25px;
    padding: 0px;
    top: 5px;
    right: 5px;
    color: rgba(var(--color-7), 1);
}

.BriefStudent .col-view:hover {
    color: rgb(var(--color-1));
    cursor: pointer;
}

.BriefStudent .react-calendar__month-view__weekdays__weekday abbr {
    color: rgb(var(--color-22));
}

@media screen and (max-width: 504px) {

    .BriefStudent .col-name {
        max-width: calc(100vw - (40px + 20px + 40px + 70px + 40px)) !important;
    }
}

@media screen and (max-width: 1546px) {

    .BriefStudent .project-info {
        flex: 0 0 auto;
        width: 100%;
        padding-right: 0px;
    }
    .BriefStudent .col-info {
        max-height: none;
    }

    .BriefStudent .calendar {
        padding-left: 0px;
    }
}

/* @media screen and (max-height: 610px) {

    .BriefStudent .col-info {
        max-height: calc(100vh - (25px + 5px + 15px + 123px + 59px + 60px + 70px));
    }

} */


.react-calendar {
    width: 700px;
    max-width: 100%;
    background-color: rgb(var(--color-10)) !important;
    color: rgb(255,255,255);
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar__navigation button {
    color: white;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 44px;
    /* background: none; */
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
    /* color: #d10000; */
    color: white;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: white;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__tile--now {
    /* background: #ffff76; */
    background-color: rgb(var(--color-18)) !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: rgb(var(--color-7)) !important;
}

.react-calendar__tile--hasActive {
    background: rgb(var(--color-1)) !important;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar__tile--active {
    background: rgb(var(--color-1)) !important;
    color: rgb(var(--color-7)) !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: rgb(var(--color-1)) !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}