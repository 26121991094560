.ModalInfo .modal-content {
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: rgb(var(--color-7));
    background-clip: padding-box;
    border-radius: 15px;
    outline: 0;
    color: rgb(var(--color-22));
}

.ModalInfo .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
    border-radius: 50px;
    color: rgb(var(--color-7));
}

.ModalInfo .contenedor-doc{
    background-color: rgba(var(--color-22), 0.219);
    border-radius: 15px;
    padding: 5%;
    margin-top: 2%;
}

.ModalInfo .scroll-files{
    max-height: 16vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.ModalInfo .scroll-comment{
    max-height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.ModalInfo .doc{
    margin-bottom: 15px;
}

.ModalInfo .boton{
    margin-top: 10px;
}

.ModalInfo .contenedor-msj{
    background-color: rgb(var(--color-7));
    border-radius: 15px;
    padding: 2%;
    margin-bottom: 10px;
}

.ModalInfo .info-fecha{
    justify-content: end;
}



.ModalInfo .drop-area {
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid rgba(var(--color-22),0.5);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 325px;
}

.ModalInfo .drop-area.active {
    width: 100%;
    border: 2px dashed  rgb(var(--color-1));
    background-color: rgba(var(--color-7), 0.1);
    color: rgb(var(--color-1));
}

.ModalInfo .drop-area:hover {
    border: 2px dashed  rgb(var(--color-1));
}

.ModalInfo .mi-imagen-gris {
    -webkit-filter: grayscale(100%) blur(1px);
    filter: grayscale(100%) blur(1px);
}

.ModalInfo .col-img {
    padding-top: 5px;
    padding-bottom: 5px;
    flex: 0 0 auto;
    width: 70px !important;
}

.ModalInfo .col-carga {
    width: 40px;
    position: absolute;
    top: 10px;
    left: 17px;
}

.ModalInfo .col-name {
    flex: 0 0 auto;
    max-width: 300px !important;
    height: 60px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    padding-top: 14px;
    font-size: 20px;
}

.ModalInfo .col-file {
    flex: 0 0 auto;
    width: 400px !important;
    margin: 5px;
    border-radius: 3px;
    background-color: rgba(var(--color-22), 0.2);
}

.ModalInfo .card-file {
    position: relative;
}

.ModalInfo .col-delete {
    color: rgb(var(--color-22));
    font-size: 25px;
    position: absolute;
    width: 25px;
    padding: 0px;
    top: 5px;
    right: 5px;
}

.ModalInfo .col-delete:hover {
    color: rgb(var(--color-1));
    cursor: pointer;
}

