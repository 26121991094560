.MyProjects .titulo{
    padding-left: 5%;
    color: rgb(var(--color-22));
    font-weight: 100;
}

.MyProjects h2{
    margin: 0px !important;
}

.MyProjects .Carta{
    orientation: center;
    margin-top: 15px;
    background-color: rgba(var(--color-25), 0.5);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    text-align: start;

    .card-body{
        padding-bottom: 0px !important;
        padding-top: 10px !important;
    }
    
}

.MyProjects .CartaCurso{
    background-color: rgba(var(--color-1), 0.5) !important;
}

.MyProjects .Carta-imagen{
    border-radius: 5px;
    height: 167px !important;
    object-fit: scale-down;
}

.MyProjects .row{
    max-width: 100%;
}

.MyProjects p{
    font-weight: 400;
}

@media screen and (max-width: 810px){
    .MyProjects .justify-content-around-810{
        justify-content: space-around !important;
    }
}

@media screen and (max-width: 760px){
    .MyProjects .titulo{
        text-align: center;
        padding-left: 0px;
    }
}