.Footer {
    text-align: center;
    position: fixed;
    bottom: 0px;
    width: 100vw;
    white-space: nowrap;
    overflow-x: auto;
    text-overflow: clip;
    background-color: rgb(var(--color-1));
    z-index: 10000;
}

.Footer a {
    text-decoration: none;
    color: rgb(var(--color-7));
}

.Footer p {
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(var(--color-7));
}