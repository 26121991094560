.ModalEmail {
    color: rgba(var(--color-22), 1) !important;
}

.ModalEmail .deleteEmail{
    cursor: pointer;
    position: absolute;
    top: 11px;
    right: 10px;
}

.ModalEmail .deleteEmail:hover{
    color: rgba(var(--color-24), 1);
}

.ModalEmail .backEmail{
    cursor: pointer;
    margin-left: 10px;
}

.ModalEmail .backEmail:hover{
    color: rgba(var(--color-23), 1);
}