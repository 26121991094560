.MyProjectsStudent{
    justify-content: center;
    padding-left: 170px;
}

.MyProjectsStudent .card-margin{
    padding-left: 7%;
    padding-right: 7%;
    max-height: calc(100vh - calc(20vh + 43px + 0.5rem + 24px));
    overflow-y: auto;
}

@media screen and (max-width: 1200px){
    .MyProjectsStudent {
        padding-left: 100px;
    }
}

@media screen and (max-width: 1085px){
    .MyProjectsStudent {
        padding-left: 50px;
    }
}

@media screen and (max-width: 842px){
    .MyProjectsStudent {
        padding-left: 0px;
    }
}

@media screen and (max-width: 760px){

    .MyProjectsStudent .card-margin{
        padding-top: 0px;
    }
}


@media screen and (max-height: 610px){

    .MyProjectsStudent .card-margin{
        max-height: calc(100vh - calc(123px + 43px + 0.5rem + 24px));
    }
}


.MyProjectsStudent .assistant .card-margin{
    padding-left: 7%;
    padding-right: 7%;
}

@media screen and (max-width: 760px){

    .MyProjectsStudent .assistant .card-margin{
        padding-top: 0px;
    }
}