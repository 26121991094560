:root {
    --color-1: 32, 170, 230;  
    --color-2: 77, 162, 193;  
    --color-3: 102, 160, 183;  
    --color-4: 122, 151, 173;  
    --color-5: 143, 143, 156;  
    --color-6: 163, 156, 145;  
    --color-7: 255, 255, 255;  
    --color-8: 229, 228, 227;  
    --color-9: 206, 203, 201;  
    --color-10: 186, 185, 182; 
    --color-11: 169, 166, 163; 
    --color-12: 152, 150, 146; 
    --color-13: 144, 157, 136; 
    --color-14: 138, 134, 128; 
    --color-15: 132, 127, 120; 
    --color-16: 126, 121, 113; 
    --color-17: 119, 106, 105; 
    --color-18: 109, 106, 100; 
    --color-19: 99, 97, 94;    
    --color-20: 89, 89, 89;    
    --color-21: 80, 82, 84;    
    --color-22: 71, 75, 78;    
    --color-23: 67, 207, 67; 
    --color-24: 207, 67, 67;
    --color-25: 254, 200, 107; 
    --color-26: 221, 179, 110;

    --color-hex-1: #20aae6;  
    --color-hex-2: #4da2c1;  
    --color-hex-3: #66a0b7;  
    --color-hex-4: #7a97ad;  
    --color-hex-5: #8f8f9c;  
    --color-hex-6: #a39c91;  
    --color-hex-7: #ffffff;  
    --color-hex-8: #e5e4e3;  
    --color-hex-9: #cecbc9;  
    --color-hex-10: #bab9b6; 
    --color-hex-11: #a9a6a3; 
    --color-hex-12: #989692; 
    --color-hex-13: #909d88; 
    --color-hex-14: #8a8680; 
    --color-hex-15: #847f78; 
    --color-hex-16: #7e7971; 
    --color-hex-17: #776a69; 
    --color-hex-18: #6d6a64; 
    --color-hex-19: #63615e; 
    --color-hex-20: #595959; 
    --color-hex-21: #505254; 
    --color-hex-22: #474b4e; 
    --color-hex-23: #43cf43;
    --color-hex-24: #cf4343;
    --color-hex-25: #fec86b; 
    --color-hex-26: #ddb36e;

}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .bs-tooltip-end .tooltip-arrow::before {
    border-right-color: rgb(var(--color-7));
}
.tooltip-inner {
    background-color: rgb(var(--color-7));
    color: rgb(var(--color-1));
}

@font-face {
    font-family: Roboto-Regular;
    src: url("./Assets/Font/Roboto-Regular.ttf");
}

*{
    font-family: Roboto-Regular , sans-serif;
}

*::-webkit-scrollbar {
    -webkit-appearance: none;
}

*::-webkit-scrollbar:vertical {
    width:7px;
}

*::-webkit-scrollbar-button:increment,*::-webkit-scrollbar-button {
    display: none;
} 

*::-webkit-scrollbar:horizontal {
    height: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(var(--color-22));
    border-radius: 10px;
    cursor: pointer !important;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: rgb(var(--color-22));
}

.row{
    --bs-gutter-x: 0;
}

.text-editor{
    line-height: 20px;
    padding-left: 25px !important;
}

.text-editor *{
    margin: 0px;
}

.text-editor h1,h2,h3,h4{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

body{
    color: rgb(var(--color-7));
    background-image: url("./Assets/Img/fondo.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    max-height: 100vh;
}

@media screen and (max-width: 500px){
    body{
        background-position: 23.8% 0%;

    background-attachment: fixed;
    }
}

@media screen and (min-width: 500px){
    body{
        background-position: 8% 0%;
        
    background-attachment: fixed;
    }
}

@media screen and (min-width: 1400px){
    body{
        background-position: 10% 0%;
    }
}


@media screen and (min-width: 1900px){
    body{
        background-position: 10% 60%;
    }
}