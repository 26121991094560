.Home .nav{
    height: 15vh;
}

.Home p{
    margin: 0;
}

.Home .name h4 {
    .profile{
        color: rgb(var(--color-22));
    }
    .logout{
        text-decoration: none;
        font-weight: 600;
        color: rgb(var(--color-22));
    }

}

.Home .name h4 .profile:hover{
    color: rgb(var(--color-4));
    cursor: pointer;
}

.Home .name h4 .logout:hover{
    color: rgb(var(--color-4));
}

.Home .img-logo{
    max-width: 300px;
    width: 90vw;
}

.Home .img-logo:hover{
    cursor: pointer;
}

.Home .logo{
    margin-left: 150px;
    display: flex;
    justify-content: flex-start!important;
}

.Home .name{
    margin-right: 150px;
    display: flex;
    justify-content: flex-end!important;
    color: rgb(var(--color-22));
}
@media screen and (max-width: 1399px){
}
/* @media screen and (max-width: 1214px){

    .Home .logo{
        margin-left: 100px;
    }
    
    .Home .name{
        margin-right: 100px;
    }
}

@media screen and (max-width: 1103px){

    .Home .logo{
        margin-left: 50px;
    }
    
    .Home .name{
        margin-right: 50px;
    }
}

@media screen and (max-width: 990px){

    .Home .logo{
        flex: 0 0 auto;
        width: 300px;

    }
}

@media screen and (max-width: 845px){

    .Home .logo{
        margin-left: 10px;
    }
    
    .Home .name{
        margin-right: 10px;
    }
} */

@media screen and (max-width: 1399px){

    .Home .nav{
        align-items: flex-end !important;
    }

    .Home .logo{
        margin-left: 0px;
        justify-content: center !important;
        flex: 0 0 auto;
        width: 100vw;
        margin-top: 20px;
    }
    
    .Home .name{
        margin-right: 0px;
        justify-content: center !important;
        flex: 0 0 auto;
        width: 100vw;
        margin-bottom: 20px;
    }

    .Home .name h4 a{
        color: rgb(var(--color-22));
    }
}

@media screen and (max-width: 430px){
    
    .Home .name h4{
        font-size: 5vw;
    }
    
}
/* 
@media screen and (max-height: 610px){
    
    .Home .name{
        margin-bottom: 0px;
    }
    .Home .nav{
        height: 123px;
    }
} */