.BriefTeacher .name-project {
    background-color: rgb(var(--color-1));
    margin-bottom: 20px;
    border-radius: 10px;
    min-height: 50px;
    position: relative;
}

.BriefTeacher .note{
    background-color: rgba(var(--color-22),0.5);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;
}

.BriefTeacher .name-project h1 {
    color: rgb(var(--color-7));
    font-size: 1.8rem;
    margin-bottom: 0px;
    padding-left: 20px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.BriefTeacher .col-info {
    max-height: calc((70vh - (40px + 60px + 20px)));
    overflow-y: auto;
}

.BriefTeacher .container-info .sticky {
    position: sticky;
    top: 0px;
    background-color: rgba(var(--color-11), 1);
    padding-left: 10px;
    z-index: 3;
}
.BriefTeacher .calendar .container-info{
    margin-bottom: 0px;
}

.BriefTeacher .project-info {
    flex: 0 0 auto;
    width: 70%;
    padding-right: 10px;
}

.BriefTeacher .calendar {
    padding-left: 10px;
    /* overflow: auto;
    max-height: calc((70vh - (50px))); */
}

.BriefTeacher .col-img {
    padding-top: 5px;
    padding-bottom: 5px;
    flex: 0 0 auto;
    width: 70px !important;
}

.BriefTeacher .col-name {
    flex: 0 0 auto;
    max-width: 300px !important;
    height: 60px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    padding-top: 14px;
    font-size: 20px;
}

.BriefTeacher .col-file {
    flex: 0 0 auto;
    width: 400px !important;
    margin: 5px;
    border-radius: 3px;
    background-color: rgba(var(--color-22), .4);
}

.BriefTeacher .card-file {
    position: relative;
}

.BriefTeacher .col-view {
    font-size: 25px;
    position: absolute;
    width: 25px;
    padding: 0px;
    top: 5px;
    right: 5px;
    color: rgba(var(--color-7), 1);
}

.BriefTeacher .col-view:hover {
    color: rgb(var(--color-1));
    cursor: pointer;
}

.BriefTeacher .edit{
    position: absolute;
    top: 5px;
    right: 10px;
}

.BriefTeacher .edit svg:hover{
    color: rgb(var(--color-22));
    cursor: pointer;
}

.BriefTeacher .drop-area {
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid rgb(var(--color-7));
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 325px;
}

.BriefTeacher .drop-area.active {
    width: 100%;
    border: 2px dashed  rgb(var(--color-1));
    background-color: rgba(var(--color-7), 0.1);
    color: rgb(var(--color-1));
}

.BriefTeacher .drop-area:hover {
    border: 2px dashed  rgb(var(--color-1));
}

.BriefTeacher .drop-area span {
    font-weight: 500;
}

.BriefTeacher .mi-imagen-gris {
    -webkit-filter: grayscale(100%) blur(1px);
    filter: grayscale(100%) blur(1px);
}

.BriefTeacher .col-carga {
    width: 40px;
    position: absolute;
    top: 10px;
    left: 17px;
}

.BriefTeacher .col-delete {
    font-size: 25px;
    position: absolute;
    width: 25px;
    padding: 0px;
    top: 5px;
    right: 5px;
}

.BriefTeacher .col-delete:hover {
    color: rgb(var(--color-24));
    cursor: pointer;
}

@media screen and (max-width: 504px) {

    .BriefTeacher .col-name {
        max-width: calc(100vw - (40px + 20px + 40px + 70px + 40px)) !important;
    }
}

@media screen and (max-width: 1546px) {

    .BriefTeacher .project-info {
        flex: 0 0 auto;
        width: 100%;
        padding-right: 0px;
    }
    .BriefTeacher .col-info {
        max-height: none;
    }

    .BriefTeacher .calendar {
        padding-left: 0px;
    }
}

/* @media screen and (max-height: 610px) {

    .BriefTeacher .col-info {
        max-height: calc(100vh - (25px + 5px + 15px + 123px + 59px + 60px + 70px));
    }

} */

.BriefTeacher .react-calendar__month-view__weekdays__weekday abbr {
    color: rgb(var(--color-22));
}