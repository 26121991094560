.TeamTeacher .groups-info {
    flex: 0 0 auto;
    width: 65%;
    padding: 10px;
    padding-left: 0px;
    padding-top: 20px;
}

.TeamTeacher .list-Student {
    padding: 10px;
    padding-right: 0px;
    padding-top: 20px;
}
.TeamTeacher .col-group {
    flex: 0 0 auto;
    width: 200px;
    padding: 10px;
    margin: 10px;
    border: 2px solid rgb(var(--color-6));
    border-radius: 10px;
}

.TeamTeacher .col-group:hover {
    background-color: rgba(var(--color-22),0.5);
    cursor: pointer;
}

.TeamTeacher .col-group .col-group-student {
    text-align: start;
}

.TeamTeacher .edit-groups{
    position: absolute;
    top: -5px;
    right: 0px;
}

.TeamTeacher .edit-groups svg:hover{
    color: rgb(var(--color-22));
    cursor: pointer;
}

.TeamTeacher .edit{
    position: absolute;
    top: 5px;
    right: 10px;
}

.TeamTeacher .edit:hover{
    color: rgb(var(--color-22));
    cursor: pointer;
}

.TeamTeacher .changeName{
    color: rgb(var(--color-1));
    background-color: rgba(var(--color-22),0);
    border-color: rgb(var(--color-1));
    border-style: solid;
    width: 300px;
}



.TeamTeacher ul {
    /* border: 1px dotted pink; */
    margin: 0;
    list-style: none;
    padding: 5px;
    border-radius: 5px;

    display: inline-flex;
    flex-direction: column;
}

.TeamTeacher li {
    color: rgb(var(--color-20));
    box-shadow: 0px 2px 4px rgb(var(--color-22));
    border-radius: 5px;
    padding: 0.3em 0.5em;
    background-color:  rgb(var(--color-7));
    text-align: center;
    margin: 5px;
    border: 2px solid transparent;
    top: auto !important;
    left: auto !important;
}

.TeamTeacher li:focus {
    outline: none;
    border: 2px solid rgb(var(--color-22));
}

@media screen and (max-width: 1199px) {

    .TeamTeacher .row-center {
        justify-content: center;
    }
    .TeamTeacher .groups-info {
        flex: 0 0 auto;
        width: 100%;
        padding-right: 0px;
    }

    .TeamTeacher .list-Student {
        padding-left: 0px;
        padding-top: 10px;
        padding-bottom: 0px;

        .container-info{
            margin-bottom: 0px;
        }
        
    }
    
}