.Project .nav-link {
    padding: 0px !important;
}

.Project .activate{
    color: rgb(var(--color-1)) !important;
}

.Project .titulo {
    color: rgb(var(--color-22));
    font-size: 1.8rem;
    text-decoration: none;
    margin-bottom: 0px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.Project .navbar-collapse .titulo {
    background-color: rgba(var(--color-8), 0.2);
}

.Project .navbar-toggler-icon {

    background-image: url("../../Assets/Img/toggler.svg")
}

.Project .navbar-light .navbar-toggler {
    border-color: rgb(var(--color-7));
}

.Project .project-outlet {
    background-color: rgba(var(--color-20), 0.8);
    padding: 20px;
    border-radius: 10px;
    margin: 15px;
    margin-right: 60px;
    margin-left: auto;
    max-width: clamp(1500px, 10vw, 3000px);
    min-height: 580px;
    height: 70vh;

}

.Project .project-outlet-overflow{
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 10px;
    height: 100%;
}

.Project .container-info .title {
    color: rgb(var(--color-25));
    font-size: 1.8rem;
    margin-bottom: 0px;
}

.Project .container-info {
    background-color: rgba(var(--color-11), 1);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

@media screen and (max-width: 991px) {

    .Project .navbar-collapse .titulo {
        background-color: rgba(var(--color-22),0.9);
    }

    .Project .navbar-collapse{
        z-index: 4;
    }

    .Project .navbar-collapse .top{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    
    .Project .navbar-collapse .bottom{
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }

}

@media screen and (max-width: 1620px) {

    .Project .project-outlet {
        margin-left: 60px;
    }

}

@media screen and (max-width: 1199px) {

    .Project .project-outlet {
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 60px;
        min-height: 70vh;
        height: auto;
    }

}

@media screen and (max-width: 575px) {

    .Project .project-outlet {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 50px;
        padding: 5px;
    }

}

@media screen and (min-width: 2000px) {

    .Project .project-outlet {
        margin-right: auto;
        margin-left: 440px;
    }

}

@media screen and (min-width: 2381px) {

    .Project .project-outlet {
        margin-right: 440px;
        margin-left: auto;
    }

}

@media screen and (min-width: 2760px) {

    .Project .project-outlet {
        margin-right: auto;
        margin-left: 820px;
    }

}

@media screen and (min-width: 3140px) {

    .Project .project-outlet {
        margin-right: auto;
        margin-left: auto;
    }

}