.SubmitSubmission .modal-content {
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: rgb(var(--color-7));
    background-clip: padding-box;
    border-radius: 15px;
    outline: 0;
    color: rgb(var(--color-22)) !important;
}

.SubmitSubmission .drop-area {
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid rgba(var(--color-22),0.5);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 325px;
}

.SubmitSubmission .drop-area.active {
    width: 100%;
    border: 2px dashed  rgb(var(--color-1));
    background-color: rgba(var(--color-7), 0.1);
    color: rgb(var(--color-1));
}

.SubmitSubmission .drop-area:hover {
    border: 2px dashed  rgb(var(--color-1));
}

.SubmitSubmission .mi-imagen-gris {
    -webkit-filter: grayscale(100%) blur(1px);
    filter: grayscale(100%) blur(1px);
}

.SubmitSubmission .col-img {
    padding-top: 5px;
    padding-bottom: 5px;
    flex: 0 0 auto;
    width: 70px !important;
}

.SubmitSubmission .col-carga {
    width: 40px;
    position: absolute;
    top: 10px;
    left: 17px;
}

.SubmitSubmission .col-name {
    flex: 0 0 auto;
    max-width: 300px !important;
    height: 60px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    padding-top: 14px;
    font-size: 20px;
}

.SubmitSubmission .col-file {
    flex: 0 0 auto;
    width: 400px !important;
    margin: 5px;
    border-radius: 3px;
    background-color: rgba(var(--color-7), 0.4);
}

.SubmitSubmission .card-file {
    position: relative;
}

.SubmitSubmission .col-delete {
    font-size: 25px;
    position: absolute;
    width: 25px;
    padding: 0px;
    top: 5px;
    right: 5px;
}

.SubmitSubmission .col-delete-2 {
    font-size: 25px;
    position: absolute;
    width: 25px;
    padding: 0px;
    top: 0px;
    right: -25px;
}

.SubmitSubmission .col-delete:hover {
    color: rgb(var(--color-24));
    cursor: pointer;
}


.SubmitSubmission .col-delete-2:hover {
    color: rgb(var(--color-24));
    cursor: pointer;
}