.Profile .contenedor {
    margin-top: 20px;
    background-color: rgba(var(--color-20), 0.8);
    border-radius: 15px;
    padding: 20px;
    padding-left: 2%;
    max-width: 1500px;
    max-height: calc(100vh - (20vh + 30px + 48px + 20px));
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;
    color: rgb(var(--color-7));
}

.Profile .titulo {
    padding-left: 5%;
    color: rgb(var(--color-1));
}

.Profile .email{
    color: rgba(var(--color-7), 0.8);
    margin-left: 20px;
}
.Profile .email:hover{
    cursor: pointer;
    color: rgba(var(--color-1), 0.8);
}